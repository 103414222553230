import React from 'react';

const Error = () => (
  <p>
    You just hit a route that doesn't exist... it's either being paved or...
    sadness.
  </p>
);

export default Error;
